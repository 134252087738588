import React from 'react';
import {
    Link
} from "react-router-dom"
import { connect } from 'react-redux'
import { getSettings } from '../redux/selectors'


class Information extends React.Component {
    render() {
        return (
            <section>
                <div className="page-container">
                    <div className="segment-intro segment-intro-info">
                    <div className="link-back">
                        &lt; <Link to="/">Go Back Home</Link>
                    </div>
                    <div className="content-container-700">
                        <div>
                        <h1>How it works</h1>
                        <p>
                            We offer pre-orders for pickup when ordering from {this.props.settings.minOrderTime} up to {this.props.settings.maxOrderTime} days in advance.
                            If you do not see a date available in the {this.props.settings.maxOrderTime}-day timeframe, it has filled. We have a {this.props.settings.minPoundsPerOrder} pound
                            minimum and a {this.props.settings.maxPoundsPerOrder} pound maximum for orders.
                            We are open for lunch only. Pre-orders are available for pickup between 10:30am and 1:30pm. Pre-orders are to go only.
                        </p>
                        <h2>Location</h2>
                        <p>
                            <em>Franklin Barbecue</em>
                            <br />
                            900 E. 11th St.
                            <br />
                            Austin, TX 78702
                        </p>
                        </div>
                    </div>
                    <hr />
                    </div>
                    <div className="content-container-700">
                    <ul className="info-blocks">
                        <li className="info-block">
                        <figure>
                            <div className="header">
                            <h2
                                style={{
                                backgroundImage: 'url("/assets/images/ico-cal-dark.svg")'
                                }}
                            >
                                Pickup Dates
                            </h2>
                            </div>
                            <p>
                            Dates open {this.props.settings.maxOrderTime} days in advance. If you do not see a date available
                            in the {this.props.settings.maxOrderTime}-day timeframe, it has filled.
                            </p>
                            <p>
                            <strong>We are closed on Mondays.</strong>
                            </p>
                        </figure>
                        </li>
                        <li className="info-block">
                        <figure>
                            <div className="header">
                            <h2
                                style={{
                                backgroundImage: 'url("/assets/images/ico-scale-dark.svg")'
                                }}
                            >
                                Order Quantity
                            </h2>
                            </div>
                            <p>We take preorders for orders between {this.props.settings.minPoundsPerOrder}-{this.props.settings.maxPoundsPerOrder} pounds of meat.</p>
                        </figure>
                        </li>
                        <li className="info-block">
                        <figure>
                            <div className="header">
                            <h2
                                style={{
                                backgroundImage: 'url("/assets/images/ico-knife-dark.svg")'
                                }}
                            >
                                Wait Times
                            </h2>
                            </div>
                            <p>
                            To ensure the freshest product possible, we slice your order upon
                            arrival. Please be aware there will be a wait time after check-in.
                            </p>
                            <p>
                            <strong>Larger orders do take longer to slice.</strong>
                            </p>
                        </figure>
                        </li>
                        <li className="info-block">
                        <figure>
                            <div className="header">
                            <h2
                                style={{
                                backgroundImage: 'url("/assets/images/ico-park-dark.svg")'
                                }}
                            >
                                Parking
                            </h2>
                            </div>
                            <p>
                                We have 3 parking spaces that are dedicated to pre-order pickup. If these spaces have filled, street parking is your best bet. We are located at 900 E. 11th street.
                            </p>
                        </figure>
                        </li>
                        <li className="info-block">
                        <figure>
                            <div className="header">
                            <h2
                                style={{
                                backgroundImage: 'url("/assets/images/ico-payment-dark.svg")'
                                }}
                            >
                                Payment
                            </h2>
                            </div>
                            <p>Full payment is due when your order is placed. No shows and orders cancelled less than 48 hours before your pick-up date will <strong>not</strong> be refunded. If you cancel 48 hours or more before your pick-up date, then you will be refunded your payment less a 5% processing fee.</p>
                        </figure>
                        </li>
                        <li className="info-block">
                        <figure>
                            <div className="header">
                            <h2
                                style={{
                                backgroundImage: 'url("/assets/images/ico-question-dark.svg")'
                                }}
                            >
                                Questions
                            </h2>
                            </div>
                            <p>
                            For questions, please email us at <a href="mailto:preorders@franklinbbq.com">
                                preorders@franklinbbq.com
                            </a>
                            </p>
                        </figure>
                        </li>
                    </ul>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    const settings = getSettings(state)
    return {
      settings
    }
}

export default connect(mapStateToProps)(Information)